<template>
	<nav id="sidebar" class="sidebar" aria-label="Main navigation">
		<a class="sidebar-brand" data-cy="dashboard-link">
			<span class="sr-only">Go to dashboard</span>
			<img src="@/assets/img/logo.png" alt="Clarus Care logo" />
		</a>
		<ul class="sidebar-items">
			<li class="sidebar-item">
				<router-link
					class="sidebar-link"
					aria-current="page"
					role="link"
					data-cy="calls-link"
					data-tooltip="Calls"
					data-position="right"
					:to="{
						name: 'app.calls',
					}"
				>
					<font-awesome-icon :icon="['fal', 'phone-volume']" />
					<span class="sr-only">Calls</span>
				</router-link>
			</li>
			<li v-if="hasPagingEnabled" class="sidebar-item">
				<router-link
					class="sidebar-link"
					data-cy="paging-link"
					aria-current="page"
					data-tooltip="Paging"
					data-position="right"
					:to="{
						name: 'app.paging',
					}"
				>
					<font-awesome-icon :icon="['fal', 'pager']" />
					<span class="sr-only">Paging</span>
				</router-link>
			</li>
			<li
				v-if="!hasPagingEnabled && haslimitedManagerAccess"
				class="sidebar-item"
			>
				<a
					target="_blank"
					class="sidebar-link"
					data-cy="paging-learn-more-link"
					data-tooltip="Paging - Learn More"
					data-position="right"
					href="https://claruscare.com/paging"
				>
					<font-awesome-icon :icon="['fal', 'pager']" />
					<span class="sr-only">Paging</span>
				</a>
			</li>
			<li class="sidebar-item">
				<router-link
					class="sidebar-link"
					data-cy="scheduling-link"
					data-tooltip="Scheduling"
					data-position="right"
					:to="{
						name: 'app.schedules',
					}"
				>
					<font-awesome-icon size="2x" :icon="['fal', 'calendar']" />
					<span class="sr-only">Scheduling</span>
				</router-link>
			</li>
			<li v-if="isActiveProfile" class="sidebar-item">
				<router-link
					class="sidebar-link flex"
					aria-current="page"
					role="link"
					data-cy="calls-link"
					data-tooltip="Messaging"
					data-position="right"
					:to="{
						name: 'app.chat',
					}"
				>
					<div
						v-if="hasUnreadMessages"
						class="absolute top-0 right-0 h-6 w-6 text-white border-2 border-gray-300 rounded-full bg-red-500 z-2 text-xs flex justify-center items-center text-center"
					>
						{{ unReadCount }}
					</div>
					<font-awesome-icon :icon="['fal', 'comments']" />
					<span class="sr-only">Messaging</span>
				</router-link>
			</li>
			<li v-if="hasOfficeManagerAccess" class="sidebar-item">
				<router-link
					class="sidebar-link"
					data-cy="providers-link"
					data-tooltip="Providers"
					data-position="right"
					:to="{
						name: 'app.providers',
					}"
				>
					<font-awesome-icon :icon="['fal', 'user-md']" />
					<span class="sr-only">Providers</span>
				</router-link>
			</li>
			<li v-if="hasOfficeManagerAccess" class="sidebar-item">
				<router-link
					exact-path
					class="sidebar-link"
					data-cy="insights-link"
					aria-current="insights"
					data-tooltip="Insights"
					data-position="right"
					:to="{
						name: 'app.dashboard',
					}"
				>
					<font-awesome-icon :icon="['fal', 'analytics']" />
					<span class="sr-only">Insights</span>
				</router-link>
			</li>
			<li v-if="hasOfficeManagerAccess" class="sidebar-item">
				<router-link
					href="#"
					class="sidebar-link"
					data-cy="settings-link"
					data-tooltip="Settings"
					data-position="right"
					:to="{
						name: 'app.settings',
					}"
				>
					<font-awesome-icon :icon="['fal', 'cog']" />
					<span class="sr-only">Settings</span>
				</router-link>
			</li>
			<li class="app-version">Version {{ appVersion }}</li>
		</ul>
	</nav>
</template>
<script>
import Push from 'push.js'
import { mapActions, mapGetters } from 'vuex'
import Application from 'App/Foundation/Application'
import { version } from '../../package.json'

const FIRST_PAGE = 0

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if there are unread messages.
		 *
		 * @return  {Boolean}
		 */
		hasUnreadMessages() {
			return this.unReadCount > 0
		},

		...mapGetters('auth', [
			'hasOfficeManagerAccess',
			'haslimitedManagerAccess',
		]),
		...mapGetters('messagingProfiles', ['isActiveProfile']),
		...mapGetters('partners', ['hasPagingEnabled']),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		...mapActions({
			fetchChannels: 'channels/get',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Sidebar',

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		let stream
		const app = Application.getInstance()

		setTimeout(() => {
			stream = app.container.use('Stream')
			this.unReadCount = stream?.user?.total_unread_count || 0
		}, 500)

		this.newMessageListener = app.container
			.use('Stream')
			.on('message.new', event => {
				try {
					Push.create(event.message.user.name, {
						body: event.message.text,
					})
				} catch (e) {
					console.error(e)
				}

				this.unReadCount = event.total_unread_count
			})

		this.addedToChannelListener = app.container
			.use('Stream')
			.on('notification.added_to_channel', async () => {
				await this.fetchChannels(FIRST_PAGE)
			})

		this.notificationReadListener = app.container
			.use('Stream')
			.on('notification.mark_read', event => {
				this.unReadCount = event.total_unread_count
			})
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.addedToChannelListener.unsubscribe()
		this.newMessageListener.unsubscribe()
		this.notificationReadListener.unsubscribe()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			addedToChannelListener: null,
			newMessageListener: null,
			appVersion: version,
			notificationReadListener: null,
			unReadCount: 0,
		}
	},
}
</script>
