<template>
	<panel :show="show" large @close="close">
		<template #title>
			Switch Partner
		</template>
		<template>
			<img
				src="@/assets/img/undraw-switches.svg"
				class="panel-illustration"
			/>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<div class="form-group">
					<label for="providerType" class="form-label">
						Active Partner
					</label>
					<multiselect
						id="provider_id"
						ref="multiselect"
						v-model="partner"
						required
						track-by="id"
						label="label"
						placeholder="Select a partner"
						deselect-label=""
						:options="allPartners"
						:multiple="false"
						:allow-empty="false"
						:select-label="''"
						:selected-label="''"
					>
						<template slot="noResult">
							No partners found
						</template>
						<template slot="singleLabel" slot-scope="{ option }">
							<div class="multiselect-item-content">
								{{ option.label }}
								<span
									v-if="shouldDisplayCallCount"
									class="partner-select-count-badge"
								>
									{{ option.pending_calls_count }}
								</span>
							</div>
						</template>
						<template slot="option" slot-scope="{ option }">
							<div class="multiselect-item-content">
								<span>
									{{ option.label }}
								</span>
								<span
									v-if="shouldDisplayCallCount"
									class="partner-select-count-badge"
								>
									{{ option.pending_calls_count }}
								</span>
							</div>
						</template>
					</multiselect>
				</div>
				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>
					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						@keydown.tab="onLastElementTab"
					>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'save']"
							aria-hidden="true"
						/>
						<span class="btn-label">Change</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { sortBy } from 'lodash'
import Multiselect from 'vue-multiselect'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions, mapGetters } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Multiselect,
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the user's available partners sorted alphabetically.
		 *
		 * @return {Array}
		 */
		allPartners() {
			if (!this.partners) {
				return []
			}

			const partners = sortBy(this.partners, [partner => partner.name])

			return partners.map(partner => {
				const pending_calls_count = partner?.pending_calls_count || 0

				return {
					id: partner.id,
					label: partner.name,
					pending_calls_count: pending_calls_count,
				}
			})
		},

		/**
		 * Determine if the incomplete call count should be displayed.
		 *
		 * @return {Boolean}
		 */
		shouldDisplayCallCount() {
			return !this.hasRole('admin')
		},

		...mapGetters({
			hasRole: 'auth/hasRole',
			partners: 'partners',
			active: 'partner',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				await this.setActive(this.partner.id)
				if (this.$route.name === 'app.providers.edit') {
					this.$router.push({ name: 'app.providers' })
				}
			} catch (e) {
				this.$alert.error('partners.change.error')
			}

			this.close()
		},

		...mapActions('partners', ['setActive']),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'SwitchPartnerPanel',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		active: {
			handler(partner) {
				if (partner) {
					this.partner = {
						id: partner.id,
						label: partner.name,
						pending_calls_count: partner.pending_calls_count,
					}
				}
			},
			immediate: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			partner: {},
		}
	},
}
</script>
